.mainMenu {
  text-align: center;
  .filters {
    font-family: $base-font;

    .manufacturers, .categories {
      text-align: center;

      .dropdown {
        position: relative;
        display: block;
        width: 100%;
        background-color: $base-color-lighter;
        border: none;
        border-radius: 5px;
        padding: 22px 15px 16px 15px;
        text-transform: uppercase;
        margin: 25px 0 0 0;
      }

      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        top: 45px;
        right: 0;
        width: 100%;
        max-height: 400px;
        overflow-x: auto;
        background-color: $base-color-lighter;
        font-weight: bold;
        position: absolute;
        color: $text-color;
        z-index: 1;
      }

      li {
        text-align: left;
        transition: all 0.3s;
        text-transform: none;
        padding: 8px 16px;

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          cursor: pointer;
          background-color: $button-color-hover;
          color: $text-color;
        }
      }

      .button {
        &:before {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border: 10px solid;
          border-color: white transparent transparent transparent;
          right: 15px;
          top: 22px;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }

    .latest, .top, .stock {
      text-align: center;
      margin: 25px 0 0 0;
      background-color: $base-color-lighter;
      border: none;
      border-radius: 5px;
      padding: 20px 15px 15px 15px;
      color: $text-color;
      text-transform: uppercase;

      &:hover {
        transition: all 1.0s;
        background-color: $button-color-hover;
      }
    }

    .active {
      background-color: $error-color;
    }
  }
}
