body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $base-color;
  color: $text-color; //#2d2f34
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

::-webkit-scrollbar {
  width: 8px;

}

::-webkit-scrollbar-track {
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: $text-color;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: $text-color;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }
}


.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.delay-1s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.animated.delay-2s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.animated.delay-3s {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated.delay-4s {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

.animated.delay-5s {
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}

.animated.fast {
  -webkit-animation-duration: 800ms;
  animation-duration: 800ms;
}

.animated.faster {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}

.animated.slow {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.animated.slower {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}


a {
  font-family: $base-font;
  font-size: 16px;
  font-weight: 500;
  color: $text-color;

  &:hover {
    color: #d9d9d9;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
    text-decoration: none;
  }
}


.MuiPaper-root {
  svg {
    margin-top: 0;
  }

  .MuiGrid-container {
    max-height: 200px;
    overflow-x: hidden;
    display: inline-block;
  }
}

@media print {
  .MUIDataTableFilterList-root-117, .MuiToolbar-root {
    display: none;
  }
}

.slick-prev:before, .slick-next:before {
  font-family: $base-font !important;
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: $text-color !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {

  &:before {
    font-family: $base-font !important;
    content: '←' !important;
    font-size: 28px !important;
    color: $text-color !important;
  }
}

.slick-next {
  &:before {
    font-family: $base-font !important;
    content: '→';
    font-size: 28px !important;
    color: $text-color !important;
  }
}

//.carousel {
//  .slide {
//    img {
//      width: auto !important;
//      max-height: 750px !important;
//    }
//  }
//}

.mrg {
  width: 95%;
}


.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  //margin-left: -30px; /* gutter size offset */
  //width: auto;
}

.my-masonry-grid_column {
  //padding-left: 15px; /* gutter size */
  //background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > section {
  /* change div to reference your elements you put in <Masonry> */
  //background: grey;
  margin: 15px 7px;
}

iframe, object, embed {
  max-width: 100%;
  border-radius: 5px;
}