.about__us {
  background-image: url("./images/about.jpg");
  z-index: -1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: auto;
  opacity: .8;
  margin: 15px 0;

  .content {
    border: none;
    font-family: $base-font;
    font-size: 28px;
    font-weight: 400;
    color: $text-color;
    padding: 3.5%;

    h1 {
      font-family: $base-font;
      font-size: 72px;
      font-weight: 400;
      color: $text-color;
      margin: 50px 0;
    }

    .why-us {
      border: none;
      padding: 15px;
      font-family: $base-font;
      font-size: 26px;
      font-weight: 400;
      color: $text-color;

      img {
        height: 120px;
        margin: 30px;
      }
    }

    .more {
      margin-top: 50px;
    }
  }

  @media screen and (max-width: 992px) {
    display: none;
  }
}


.footer {
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 100%;
  font-family: $base-font;
  font-size: 22px;
  font-weight: 400;
  color: $text-color;
  //margin-top: 100px;

  .contact {
    position: relative;
    bottom: 200px;

    .col-12 {
      border-right: 1px solid rgba(232, 224, 211, 0.2);

      &:last-child {
        border-right: none;
      }
    }

    @media screen and (max-width: 992px) {
      .col-12 {
        border-right: 1px solid transparent;
      }
    }

    strong {
      font-size: 36px;
      font-weight: 600;
    }

    @media screen and (max-width: 992px) {
      top: 25px;
      bottom: 50px;
    }
  }

  &:before {
    position: relative;
    display: inline-block;
    left: 25px;
    background-image: url("./images/branch_left.svg");
    z-index: 2;
    content: "";
    background-repeat: no-repeat;
    height: 600px;
    width: 200px;
  }

  &:after {
    position: relative;
    display: inline-block;
    right: 100px;
    background-image: url("./images/branch_right.svg");
    z-index: 2;
    content: "";
    background-repeat: no-repeat;
    height: 475px;
    width: 300px;
  }

  @media screen and (max-width: 992px) {
    &:before, &:after {
      display: none;
    }
  }

  .scrollButton {
    position: fixed;
    bottom: 15px;
    left: 15px;
    background: $base-color-lighter;
    border-radius: 5px 0 0 5px;
    font-family: $base-font;
    font-size: 18px;
    font-weight: 400;
    color: $text-color;
    border: none;
    padding: 10px 15px;

    &:before {
      content: "↑";
    }

    &:hover {
      background-color: $button-color-hover;
    }
  }

  .backButton {
    position: fixed;
    bottom: 15px;
    left: 65px;
    background: $base-color-lighter;
    border-radius: 0 5px 5px 0;
    font-family: $base-font;
    font-size: 18px;
    font-weight: 400;
    color: $text-color;
    border: none;
    padding: 10px 15px;

    &:hover {
      background-color: $button-color;
    }
  }

}

.ncopy {
  text-align: center;
  margin: 30px 0;
  font-family: $base-font;
  font-size: 14px;
  font-weight: 400;
  color: $text-color;
  text-decoration: none;
  opacity: 0.4;
}
