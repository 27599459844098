@import "variables";
@import "system";
@import "fonts";
@import "header";
@import "menu";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

.categoriesBlock {
  display: block;

  .card-deck {
    margin: 5px 0 15px 0;

    .card {
      background-color: $base-color-lighter;
      border: none;
      font-family: $base-font;
      font-size: 14px;
      font-weight: 400;
      color: $text-color;
      overflow: hidden;
      margin: 15px 0 15px 0;
      opacity: 0.9;
      border-radius: 5px;

      img {
        max-width: 100%;
        transition: all 1.0s;
        display: block;
        width: 100%;
        height: auto;
        //transform: scale(1);
      }

      .card-body {
        padding: 0;

        .card-title {
          position: relative;
          top: 0;
          font-family: $base-font;
          font-size: 17px;
          font-weight: 500;
          color: #303030;
          margin-bottom: 0;
          border-top: none;
          padding: 15px;
          text-align: center;
          text-transform: uppercase;
          background-color: #E8E0D3;
          opacity: .8;

          &:hover {
            text-shadow: none;
            transition: all 0.8s;
            background-color: $button-color-hover;
            color: $text-color;
          }
        }
      }

      &:hover {
        text-shadow: none;
        transition: all 0.1s;
        transform: scale(1.01);
        //background-color: $base-color-lighter;
        -webkit-box-shadow: 1px -1px 30px 13px rgba(34, 60, 80, 1) inset;
        -moz-box-shadow: 1px -1px 30px 13px rgba(34, 60, 80, 1) inset;
        box-shadow: 1px -1px 30px 13px rgba(34, 60, 80, 1) inset;
      }

    }
  }
}


.latest {
  .title {
    font-family: $base-font;
    font-size: 22px;
    font-weight: 400;
    color: $text-color;
    text-transform: uppercase;
    margin-top: 25px;
  }

  .card-deck {
    margin: 0 0 15px 0;

    .card {
      background-color: $base-color-lighter;
      border: none;
      font-family: $base-font;
      font-size: 14px;
      font-weight: 400;
      color: $text-color;
      overflow: hidden;
      margin: 15px 0 15px 0;

      img {
        max-width: 100%;
        transition: all 1.0s;
        display: block;
        width: 100%;
        height: auto;
        //transform: scale(1);
      }

      .card-body {
        padding: 0;

        .card-title {
          font-family: $base-font;
          font-size: 18px;
          font-weight: 500;
          color: $base-color-lighter;
          margin-bottom: 0;
          border-top: none;
          padding: 10px;
          text-align: center;
          opacity: 0.8;
          //text-transform: uppercase;
          background-color: $text-color;
        }
      }

      &:hover {
        .card-title {
          //transition: all 1.0s;
          background-color: $button-color-hover;
          color: $text-color;
          text-shadow: none;
        }

        //transition: all 0.5s;
        //transform: scale(1.05);
        //background-color: $base-color-lighter;
        //box-shadow: 0 0 10px 5px rgba(221, 221, 221, 0.01);
      }

    }
  }
}

.top {
  .title {
    font-family: $base-font;
    font-size: 22px;
    font-weight: 400;
    color: $text-color;
    text-transform: uppercase;
    margin-top: 25px;
  }

  .card-deck {
    margin: 0 0 15px 0;

    .card {
      background-color: $base-color-lighter;
      border: none;
      font-family: $base-font;
      font-size: 14px;
      font-weight: 400;
      color: $text-color;
      overflow: hidden;
      margin: 15px 0 15px 0;

      img {
        max-width: 100%;
        transition: all 1.0s;
        display: block;
        width: 100%;
        height: auto;
        //transform: scale(1);
      }

      .card-body {
        padding: 0;

        .card-title {
          font-family: $base-font;
          font-size: 18px;
          font-weight: 500;
          color: $base-color-lighter;
          margin-bottom: 0;
          border-top: none;
          padding: 10px;
          text-align: center;
          opacity: 0.8;
          //text-transform: uppercase;
          background-color: $text-color;
        }
      }

      &:hover {
        .card-title {
          //transition: all 1.0s;
          background-color: $button-color-hover;
          color: $text-color;
          text-shadow: none;
        }

        //transition: all 0.5s;
        //transform: scale(1.05);
        //background-color: $base-color-lighter;
        //box-shadow: 0 0 10px 5px rgba(221, 221, 221, 0.01);
      }

    }
  }
}

.stock {
  .title {
    font-family: $base-font;
    font-size: 22px;
    font-weight: 400;
    color: $text-color;
    text-transform: uppercase;
    margin-top: 25px;
  }

  .card-deck {
    margin: 0 0 15px 0;

    .card {
      background-color: $base-color-lighter;
      border: none;
      font-family: $base-font;
      font-size: 14px;
      font-weight: 400;
      color: $text-color;
      overflow: hidden;
      margin: 15px 0 15px 0;

      img {
        max-width: 100%;
        transition: all 1.0s;
        display: block;
        width: 100%;
        height: auto;
        //transform: scale(1);
      }

      .card-body {
        padding: 0;

        .card-title {
          font-family: $base-font;
          font-size: 18px;
          font-weight: 500;
          color: $base-color-lighter;
          margin-bottom: 0;
          border-top: none;
          padding: 10px;
          text-align: center;
          opacity: 0.8;
          //text-transform: uppercase;
          background-color: $text-color;
        }
      }

      &:hover {
        .card-title {
          //transition: all 1.0s;
          background-color: $button-color-hover;
          color: $text-color;
          text-shadow: none;
        }

        //transition: all 0.5s;
        //transform: scale(1.05);
        //background-color: $base-color-lighter;
        //box-shadow: 0 0 10px 5px rgba(221, 221, 221, 0.01);
      }

    }
  }
}

.otherProducts {
  margin-top: 10px;
  padding: 50px;

  .title {
    display: block;
    font-family: $base-font;
    font-size: 2.5vh;
    font-weight: 400;
    color: $text-color;
    text-transform: uppercase;
    margin: 5px 15px 55px 15px;
  }

  .card {
    background-color: $base-color-lighter;
    border: none;
    font-family: $base-font;
    font-size: 18px;
    font-weight: 400;
    color: $text-color;
    overflow: hidden;
    margin: 5px 0 15px 0;

    img {
      max-width: 100%;
      transition: all 1.0s;
      display: block;
      width: 100%;
      height: auto;
      transform: scale(1);
    }

    .card-footer {
      border-top: none;
    }

    &:hover {
      transform: scale(1.05);
      background-color: $base-color-lighter;
      box-shadow: 0 0 10px 5px rgba(221, 221, 221, 0.01);
    }
  }
}

@import "product";

.btn-main {
  font-family: $base-font;
  font-size: 16px;
  font-weight: 400;
  background-color: $button-color;
  color: $text-color;
  padding: 15px;

  &:hover {
    -webkit-transition: 1s;
    -moz-transition: 1s;
    transition: 1s;
    background-color: lighten($button-color, 5%);
    color: $text-color;
  }
}

.grayscale {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  //-ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  //filter: gray; /* IE 6-9 */
  opacity: 0.3;
}

.loader {
  margin: 30% auto;
  background-image: url("./images/logo.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150px 150px;
  height: 150px;
  width: 150px;
  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}


.load_more {
  display: block;
  margin: 0 auto;
}

.Modal {
  position: absolute;
  top: 40px;
  left: 0;
  right: 40px;
  bottom: 40px;
  font-family: $base-font;
  font-size: 14px;
  font-weight: 400;
  color: $text-color;
  background-color: $base-color;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $base-color-lighter;
  opacity: 0.98;
  z-index: 999;
}

.no-authorize {
  font-family: $base-font;
  font-size: 14px;
  font-weight: 400;
  color: $text-color;
  margin: 5px auto;
  max-width: 500px;
  background-color: $base-color-lighter;
  border: none;
  border-radius: 7px;
  padding: 25px;
  text-align: center;
}

.about-us {
  font-family: $base-font;
  font-size: 16px;
  font-weight: 400;
  color: $text-color;
  margin: 30px auto 20px auto;
  max-width: 1400px;

  .why-us {
    background-color: $base-color-lighter;
    border: none;
    border-radius: 7px;
    padding: 25px;
    font-family: $base-font;
    font-size: 16px;
    font-weight: 400;
    color: $text-color;
  }

  .more {
    font-family: $base-font;
    font-size: 16px;
    font-weight: 400;
    color: $text-color;
    margin: 50px auto;
    background-color: $base-color-lighter;
    border: none;
    border-radius: 7px;
    padding: 25px 25px 35px 25px;
  }

  .gallery {
    font-family: $base-font;
    font-size: 16px;
    font-weight: 400;
    color: $text-color;
    margin: -20px auto;
    background-color: $base-color-lighter;
    border: none;
    border-radius: 7px;
    padding: 25px;
  }

  .fb_widget {
    font-family: $base-font;
    font-size: 16px;
    font-weight: 400;
    color: $text-color;
    margin: 50px auto;
    background-color: $base-color-lighter;
    border: none;
    border-radius: 7px;
    padding: 25px;

    .fb_iframe_widget {
      iframe {
        border-radius: 10px;
      }
    }
  }
}

#beginProducts {
  position: absolute;
  margin-top: -10px;
}

.no-product {
  text-align: center;
  background-image: url("./images/empty_product.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
  width: 200px;
  margin: 50px auto;
  background-color: $base-color;
  border-radius: 10px;
  font-family: $base-font;
  font-size: 13px;
  font-weight: 400;
  color: $text-color;
  display: block;
}

.ProductList {
  .title {
    font-family: $base-font;
    font-size: 22px;
    font-weight: 400;
    color: $text-color;
    text-transform: uppercase;
    margin-top: 25px;
  }

  .card-deck {
    //margin: 15px 0 15px 0;

    .card {
      background-color: $base-color-lighter;
      border: none;
      font-family: $base-font;
      font-size: 14px;
      font-weight: 400;
      color: $text-color;
      overflow: hidden;
      margin: 15px 0 15px 0;

      img {
        max-width: 100%;
        transition: all 1.0s;
        display: block;
        width: 100%;
        height: auto;
        //transform: scale(1);
      }

      .card-body {
        padding: 0;

        .card-title {
          font-family: $base-font;
          font-size: 18px;
          font-weight: 500;
          color: $base-color-lighter;
          margin-bottom: 0;
          border-top: none;
          padding: 10px;
          text-align: center;
          opacity: 0.8;
          //text-transform: uppercase;
          background-color: $text-color;
        }
      }

      &:hover {
        .card-title {
          //transition: all 1.0s;
          background-color: $button-color-hover;
          color: $text-color;
          text-shadow: none;
        }

        //transition: all 0.5s;
        //transform: scale(1.05);
        //background-color: $base-color-lighter;
        //box-shadow: 0 0 10px 5px rgba(221, 221, 221, 0.01);
      }

    }
  }
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus, .image-gallery-thumbnail:hover {
  outline: none;
  border: 3px solid $button-color-hover;
}

@import "./src/pages/contacts/index";
@import "control-panel";
@import "delivery";
@import "404";
@import "footer";
