.map-svg {
  position: relative;
  margin: 10px auto 50px auto;
  .map {
    position: absolute;
    width: auto;
    height: 650px;
    margin: 10px auto;
    background-size: contain;

    #Fill-1 {
      background-color: #ff253a;
    }
  }
}

.info-box {
  position: absolute;
  bottom: 0;
  right: 600px;
  left: 0;
  top: 450px;
  background-color: $base-color-lighter;
  font-family: $base-font;
  font-size: 14px;
  font-weight: 400;
  color: $text-color;
  text-decoration: none;
  height: 100px;
  width: 270px;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0.9;
  padding: 15px;
  margin: 0 auto;

  span {
    display: block;
    margin-top: 15px;
  }
}

svg {
  margin-top: 50px;

  polyline {
    //stroke: black;
    opacity: 1;

    &:hover {
      fill: $error-color;
      //stroke: blue;
      opacity: 1;
    }
  }

  #Fill-4, #Fill-5, #Fill-6, #Fill-7, #Fill-8, #Fill-9, #Fill-10, #Fill-23, #Fill-25, #Fill-26, #Fill-29 {
    fill: #009858;

    &:hover {
      fill: $success-color;
    }
  }
}

.delivery-block {
  .info {
    background-color: $base-color-lighter;
    font-family: $base-font;
    font-size: 14px;
    font-weight: 400;
    color: $text-color;
    text-decoration: none;
    padding: 15px;
    border-radius: 5px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    //opacity: 0.6;
    width: 100%;

    h4 {
      font-family: $base-font;
      font-size: 24px;
      font-weight: 400;
      color: $text-color;
      text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
      margin-bottom: 35px;
    }

    .row {
      margin: 15px 0;
    }
  }

  @media (max-width: 1000px) {
    padding-top: 20px;
  }
}