.login-form {
  margin: 7% auto;
  max-width: 700px;
  background-color: $base-color-lighter;
  border: none;
  border-radius: 7px;
  padding: 25px;
  font-family: $base-font;
  font-size: 14px;
  font-weight: 400;

  input {
    background-color: $base-color;
    border: none;
    border-radius: 7px;
    padding: 25px;
  }

  h1 {
    text-align: center;
    margin: 20px 0;
  }

  .error {
    background-color: $error-color;
    border-radius: 5px;
    color: $text-color;
    padding: 15px;
    margin: 10px 0;
  }
}

.control-panel-category {
  margin: 5px auto;
  //max-width: 900px;

  .title {
    padding-bottom: 25px;
    font-family: $base-font;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 400;
  }

  .informer, .error {
    background-color: $error-color;
    border-radius: 5px;
    color: $text-color;
    padding: 15px;
    margin: 10px 0;
    font-family: $base-font;
    font-size: 14px;
    font-weight: 400;
  }

  .informer, .success {
    background-color: $success-color;
    border-radius: 5px;
    color: $text-color;
    padding: 15px;
    margin: 10px 0;
    font-family: $base-font;
    font-size: 14px;
    font-weight: 400;
  }

  .add-category-form {
    background-color: $base-color-lighter;
    border: none;
    border-radius: 7px;
    padding: 25px;

    input {
      background-color: $base-color;
      color: $text-color;
      border: none;
      border-radius: 7px;
      padding: 25px;
    }
  }

  .categories {

    background-color: $base-color-lighter;
    border: none;
    border-radius: 7px;
    margin: 20px 0;
    max-height: 450px;
    overflow-x: auto;

    .row {
      display: flex;
      flex-wrap: wrap;
      margin-right: 0;
      margin-left: 0;
    }

    ol {
      margin-left: -35px;

      li {
        display: block;
        font-family: $base-font;
        font-size: 14px;
        font-weight: 400;
        color: $text-color;
        padding: 15px;

        a {
          color: $text-color;
          display: block;
        }

        span {
          position: absolute;
          top: 15px;
          right: 10px;
          cursor: pointer;

          &:hover {
            color: $error-color;
            cursor: pointer;
          }
        }

        &:hover {
          cursor: pointer;
          background-color: $base-color;
        }
      }
    }
  }
}

.control-panel-manufacturers {
  margin: 5px auto;
  //max-width: 900px;

  .title {
    padding-bottom: 25px;
    font-family: $base-font;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 400;
  }

  .informer, .error {
    background-color: $error-color;
    border-radius: 5px;
    color: $text-color;
    padding: 15px;
    margin: 10px 0;
    font-family: $base-font;
    font-size: 14px;
    font-weight: 400;
  }

  .informer, .success {
    background-color: $success-color;
    border-radius: 5px;
    color: $text-color;
    padding: 15px;
    margin: 10px 0;
    font-family: $base-font;
    font-size: 14px;
    font-weight: 400;
  }

  .add-manufacturers-form {
    background-color: $base-color-lighter;
    border: none;
    border-radius: 7px;
    padding: 25px;

    input {
      background-color: $base-color;
      color: $text-color;
      border: none;
      border-radius: 7px;
      padding: 25px;
    }
  }

  .manufacturers {

    background-color: $base-color-lighter;
    border: none;
    border-radius: 7px;
    margin: 20px 0;
    max-height: 450px;
    overflow-x: auto;

    .row {
      display: flex;
      flex-wrap: wrap;
      margin-right: 0;
      margin-left: 0;
    }

    ol {
      margin-left: -35px;

      li {
        display: block;
        font-family: $base-font;
        font-size: 14px;
        font-weight: 400;
        color: $text-color;
        padding: 15px;

        a {
          color: $text-color;
          display: block;
        }

        span {
          position: absolute;
          top: 15px;
          right: 10px;
          cursor: pointer;

          &:hover {
            color: $error-color;
            cursor: pointer;
          }
        }

        &:hover {
          cursor: pointer;
          background-color: $base-color;
        }
      }
    }
  }
}


.control-panel-add-product {
  margin: 5px auto;
  //max-width: 900px;

  .title {
    padding-bottom: 25px;
    font-family: $base-font;
    font-size: 22px;
    font-weight: 400;
  }

  .informer, .error {
    background-color: $error-color;
    border-radius: 5px;
    color: $text-color;
    padding: 15px;
    margin: 10px 0;
    font-family: $base-font;
    font-size: 13px;
    font-weight: 400;
  }

  .informer, .success {
    background-color: $success-color;
    border-radius: 5px;
    color: $text-color;
    padding: 15px;
    margin: 10px 0;
    font-family: $base-font;
    font-size: 13px;
    font-weight: 400;
  }

  .add-product-form {
    background-color: $base-color-lighter;
    border: none;
    border-radius: 7px;
    padding: 25px;

    .custom-control-label {
      &:before {
        color: #fff;
        border-color: $button-color-hover !important;
        background-color: $button-color-hover !important;
      }
    }

    input, textarea, select {
      background-color: $base-color;
      font-family: $base-font;
      font-size: 14px;
      font-weight: 400;
      color: $text-color;
      border: none;
      border-radius: 7px;
      margin: 10px 0;
      padding: 20px;
    }

    .select-control {
      padding: 5px 5px 5px 15px;
    }

    .file-control {
      padding: 5px;
      margin-top: -15px;
    }

    .stock {
      margin-top: 15px;
    }
  }

  .product {
    background-color: $base-color-lighter;
    border: none;
    border-radius: 7px;
    margin: 20px 0;

    .row {
      display: flex;
      flex-wrap: wrap;
      margin-right: 0;
      margin-left: 0;
    }

    .list {
      font-family: $base-font;
      font-size: 14px;
      font-weight: 400;
      color: $text-color;
      padding: 15px 0;

      .delete {
        color: $error-color;

        &:hover {
          color: $error-color;
          cursor: pointer;
        }
      }

      &:hover {
        background-color: $base-color;
        cursor: pointer;
      }
    }
  }

  #upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 10%;
    height: 100px;
    outline: 1px dashed $base-color;
    outline-offset: -10px;
    color: $text-color;
  }

  #upload-container {
    div {
      background-color: $base-color;
      position: relative;
      z-index: 10;
    }

    input[type=file] {
      margin: 15px 0;
      opacity: 0.6;
    }

    label {
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}

.control-panel-edit-product {
  margin: 5px auto;
  max-width: 1400px;

  .title {
    padding-bottom: 25px;
    font-family: $base-font;
    font-size: 22px;
    font-weight: 400;
  }

  .edit-product-form {
    margin: 5px auto;
    max-width: 1100px;
    background-color: $base-color-lighter;
    border: none;
    border-radius: 7px;
    padding: 25px;
    font-family: $base-font;
    font-size: 14px;
    font-weight: 400;
    color: $text-color;

    .custom-control-label {
      &:before {
        color: #fff;
        border-color: $button-color-hover !important;
        background-color: $button-color-hover !important;
      }
    }

    input, textarea, select {
      background-color: $base-color;
      color: $text-color;
      border: none;
      border-radius: 7px;
      margin: 10px 0;
      padding: 20px;
    }

    .select-control {
      padding: 5px 5px 5px 15px;
    }

    .file-control {
      padding: 5px;
      margin-top: 15px;
    }

    .stock, .status {
      margin-top: 17px;
    }
  }

  #upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 10%;
    height: 100px;
    outline: 1px dashed $base-color;
    outline-offset: -10px;
    color: $text-color;
  }

  #upload-container {
    div {
      background-color: $base-color;
      position: relative;
      z-index: 10;
    }

    input[type=file] {
      margin: 15px 0;
      opacity: 0.6;
    }

    label {
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .edit-product-form {
    background-color: $base-color-lighter;
    border: none;
    border-radius: 7px;
    padding: 25px;
  }

  .ReactTable .rt-thead.-filters input, .ReactTable .rt-thead.-filters select {
    border: none;
    border-radius: 7px;
    background: $base-color;
    font-family: $base-font;
    font-size: 14px;
    font-weight: 400;
    color: $text-color;
    outline-width: 0;
  }

  .ReactTable {
    background-color: $base-color-lighter;
    border: none;
    border-radius: 7px;
    padding: 25px;
    font-family: $base-font;
    font-size: 14px;
    font-weight: 400;
    max-height: 750px;
    color: $text-color;

    .rt-thead {
      position: sticky;
      top: 0;
      z-index: 2;
      overflow-y: scroll;
    }

    .pagination-bottom, .pagination-top {
      border-top: none !important;

      .-pagination {
        .-previous, .-next {
          .-btn {
            color: $text-color;
            background-color: $base-color;

            &:hover {
              background-color: $base-color;
            }
          }
        }

        .-pageJump {
          input {
            background-color: $base-color;
            color: $text-color;
          }
        }

        select {
          background-color: $base-color;
          color: $text-color;
        }
      }
    }

    .rt-table {
      .rt-thead {
        input {
          background-color: $base-color;
          color: $text-color;
        }
      }

      .rt-tbody {
        .rt-tr-group {
          &:hover {
            background-color: $base-color;
            cursor: context-menu;
          }
        }
      }
    }

    .rt-noData {
      background-color: $base-color;
      color: $text-color;
      border-radius: 5px;
    }
  }
}

.control-panel {
  margin: 5px auto;
  max-width: 1000px;

  .title {
    padding-bottom: 25px;
    font-family: $base-font;
    font-size: 22px;
    font-weight: 400;
  }

}

.control-navigation {
  margin: 25px 0;

  .nav {

    #exit {
      background-color: #ff253a;
    }

    .active {
      border-radius: 5px;
      color: #009858;
    }

    li {
      background-color: $base-color-lighter;
      border-radius: 5px;
      padding: 10px;
      margin: 10px;
      font-family: $base-font;
      font-size: 14px;
      font-weight: 400;
      color: $text-color;
      text-transform: uppercase;

      &:hover {
        background-color: $button-color-hover;
        color: $text-color;
      }
    }
  }
}