.product {
  background: url("./images/ellipse.svg"), url("./images/ellipse.svg"), url("./images/ellipse.svg"), url("./images/ellipse.svg");
  background-position: 80% 90%, 35% 0%, 5% 52%, 110% 5%;
  background-size: 30% 30%, 25% 25%, 20% 15%, 20% 15%;
  background-repeat: no-repeat, no-repeat;

  .block {
    border-radius: 10px;
    padding: 25px;
    font-family: $base-font;
    font-size: 18px;
    font-weight: 400;
    color: $text-color;

    .breadcrumb {
      background: none;
      margin-bottom: 50px;

      a {
        font-family: $base-font;
        font-size: 22px;
        font-weight: 400;
        color: $text-color;
      }
    }

    .info {
      .about {
        padding-top: 5px;

        h1 {
          margin: 5px 0 55px 0;
          font-family: $base-font;
          font-size: 48px;
          font-weight: 600;
          color: $text-color;
        }
      }

      .carousel {
        //background-color: $base-color;
        border-radius: 5px;
        opacity: 0.9;

        .slide {
          background: transparent;
        }

        .carousel-status {
          position: absolute;
          top: 0;
          right: 0;
          padding: 5px;
          font-size: 10px;
          text-shadow: none;
          color: transparent;
        }

        .carousel-slider {
          .control-arrow {
            opacity: 1;
            top: 0;
            color: #000;
            font-size: 26px;
            bottom: 0;
            margin-top: 0;
            padding: 5px;
          }
        }

        .control-dots {
          .dot {
            box-shadow: none;
            transition: opacity .25s ease-in;
            background-color: #ccc;
            border-radius: 5px;
            width: 30px;
            height: 8px;
            cursor: pointer;
            display: inline-block;
            margin: 0 8px;

            .selected {
              background-color: $base-color-lighter;
            }
          }
        }
      }
    }

    .buy {
      background-color: $base-color-lighter;
      padding: 35px;
      border-radius: 15px;
      margin: 55px 3% 0 0;
      font-family: $base-font;
      font-size: 2vh;
      font-weight: 400;
      color: $text-color;
    }

    .description {
      background-color: $base-color-lighter;
      padding: 35px;
      border-radius: 15px;
      margin: 55px 2.5% -35px 2.5%;
      font-family: $base-font;
      font-size: 2vh;
      font-weight: 400;
      color: $text-color;
    }
  }
}
