.contacts {
  background-color: $base-color-lighter;
  color: $text-color;
  padding: 25px;
  text-align: center;
  border-radius: 5px;
  margin: 30px 0;
  font-family: $base-font;
  font-size: 16px;
  font-weight: 400;

  .socials {
    position: relative;
    left: 34%;
    right: 0;
    width: 33%;

    .viber {
      position: relative;
      background-color: #2A2A2A;
      height: 65px;
      width: 65px;
      top: 90px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      padding: 15px;
      border-radius: 15px;

      &:hover {
        cursor: pointer;

        svg {

          path {
            fill: #8A2BE2;
          }
        }
      }

      svg {
        position: relative;
        top: -50px;
        height: 35px;
        width: 35px;

        path {
          fill: $text-color;
        }
      }
    }

    .instagram {
      position: relative;
      background-color: #2A2A2A;
      height: 65px;
      width: 65px;
      padding: 15px;
      border-radius: 15px;
      top: 25px;
      left: 27%;
      right: 0;
      bottom: 0;
      z-index: 2;

      &:hover {
        cursor: pointer;

        svg {

          path {
            fill: rgba(255, 37, 58, 0.6);
          }
        }
      }

      svg {
        position: relative;
        top: -50px;
        height: 35px;
        width: 35px;

        path {

        }
      }
    }

    .telegram {
      position: relative;
      background-color: #2A2A2A;
      height: 65px;
      width: 65px;
      padding: 15px;
      border-radius: 15px;
      top: -40px;
      left: 52%;
      right: 0;
      bottom: 0;
      z-index: 2;

      &:hover {
        cursor: pointer;

        svg {

          path {
            fill: #4682B4;
          }
        }
      }

      svg {
        position: relative;
        top: -50px;
        height: 35px;
        width: 35px;

        path {
        }
      }
    }

    .facebook {
      position: relative;
      background-color: #2A2A2A;
      height: 65px;
      width: 65px;
      padding: 15px;
      border-radius: 15px;
      top: -105px;
      left: 77%;
      right: 0;
      bottom: 0;
      z-index: 2;

      &:hover {
        cursor: pointer;

        svg {
          transform: rotateY(360deg);
          animation-duration: 2s;

          path {
            fill: #1E90FF;
          }
        }
      }

      svg {
        position: relative;
        top: -50px;
        height: 35px;
        width: 35px;

        path {
        }
      }
    }

    .call {
      background-color: #2A2A2A;
      width: 100%;
      padding: 10px;
      text-align: center;
      border-radius: 15px;
      display: block;
      position: relative;
      font-family: $base-font;
      font-size: 28px;
      top: -90px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;

      &:hover {
        cursor: pointer;
      }
    }

  }

  @media screen and (max-width: 992px) {
    .socials {
      width: 90%;
      left: 5%;
    }
  }

}
