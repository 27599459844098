.header {
  height: 350px;
  margin-top: -60px;
  background-image: url("./images/wave.svg");
  z-index: 2;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: auto;

  svg {
    path {
      fill: $base-color-lighter;
    }
  }

  .logo {
    position: relative;
    top: 90px;
    left: 15%;
    text-align: center;

    img {
      z-index: 2;
      height: 160px;
      width: 160px;
    }
  }

  @media screen and (max-width: 992px) {
    .logo {
      position: relative;
      top: 90px;
      left: 0;
      text-align: center;
    }
  }

  .menu {
    position: relative;
    top: 100px;
    text-align: center;
    z-index: 2;

    .navbar {
      padding: 0;
      left: 5%;

      .nav {
        .nav-item {
          margin-top: -7px;
          margin-right: 70px;
          font-family: $base-font;
          font-size: 22px;
          font-weight: 500;
          color: $text-color;
          text-decoration: none;
          text-transform: uppercase;

          &:hover {
            color: #d9d9d9;
            text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
          }
        }

        @media screen and (max-width: 992px) {
          .navbar {
            left: 0;
          }
          .nav-item {
            font-size: 1.7vh;
            margin-top: 25px;
            margin-right: 35px;
          }
        }
      }
    }
  }

  .socials {
    width: 300px;
    text-align: center;

    .viber {
      position: relative;
      background-color: #2A2A2A;
      height: 65px;
      width: 65px;
      top: 90px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      padding: 15px;
      border-radius: 15px;

      &:hover {
        cursor: pointer;

        svg {

          path {
            fill: #8A2BE2;
          }
        }
      }

      svg {
        position: relative;
        top: -50px;
        height: 35px;
        width: 35px;

        path {
          fill: $text-color;
        }
      }
    }

    .instagram {
      position: relative;
      background-color: #2A2A2A;
      height: 65px;
      width: 65px;
      padding: 15px;
      border-radius: 15px;
      top: 25px;
      left: 27%;
      right: 0;
      bottom: 0;
      z-index: 2;

      &:hover {
        cursor: pointer;

        svg {

          path {
            fill: rgba(255, 37, 58, 0.6);
          }
        }
      }

      svg {
        position: relative;
        top: -50px;
        height: 35px;
        width: 35px;

        path {

        }
      }
    }

    .telegram {
      position: relative;
      background-color: #2A2A2A;
      height: 65px;
      width: 65px;
      padding: 15px;
      border-radius: 15px;
      top: -40px;
      left: 52%;
      right: 0;
      bottom: 0;
      z-index: 2;

      &:hover {
        cursor: pointer;

        svg {

          path {
            fill: #4682B4;
          }
        }
      }

      svg {
        position: relative;
        top: -50px;
        height: 35px;
        width: 35px;

        path {
        }
      }
    }

    .facebook {
      position: relative;
      background-color: #2A2A2A;
      height: 65px;
      width: 65px;
      padding: 15px;
      border-radius: 15px;
      top: -105px;
      left: 77%;
      right: 0;
      bottom: 0;
      z-index: 2;

      &:hover {
        cursor: pointer;

        svg {
          transform: rotateY(360deg);
          animation-duration: 2s;

          path {
            fill: #1E90FF;
          }
        }
      }

      svg {
        position: relative;
        top: -50px;
        height: 35px;
        width: 35px;

        path {
        }
      }
    }

    .call {
      background-color: #2A2A2A;
      width: 100%;
      padding: 10px;
      text-align: center;
      border-radius: 15px;
      display: block;
      position: relative;
      font-family: $base-font;
      font-size: 28px;
      top: -90px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;

      &:hover {
        cursor: pointer;
      }
    }

  }

  .wave2 {
    background-image: url("./images/wave2.svg");
  }

  @media screen and (min-width: 1024px) {
    //svg {
    //  -webkit-transform: scale(1.15);
    //  transform: scale(1.15);
    //}
  }
}

.mainBg {
  margin-bottom: -145px;

  .preview {
    position: relative;
    top: -200px;
    background-image: url("./images/title.jpg");
    z-index: -1;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 1130px;
    width: auto;
    opacity: .9;

    .title {
      color: #E6D4C2;
      position: relative;
      z-index: 3;
      top: 30%;
      text-align: right;
      font-family: $base-font;
      left: 50%;
      right: 0;
      border: 0;
      font-size: 119px;
      width: 45%;
      line-height: 15vh;
      text-shadow: 2px 2px 2px rgba(150, 150, 150, 1);
      opacity: .95;
    }

    &:after {
      position: absolute;
      bottom: -40px;
      background-image: url("./images/wave2.svg");
      z-index: 2;
      content: "";
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      height: 300px;
      width: 100%;
    }

    @media screen and (max-width: 576px) {
      .title {
        width: 75%;
        left: 30%;
      }
    }
  }
}

.search_bar {

  ::placeholder {
    text-transform: uppercase;
    color: $text-color;
    font-size: 1.1em;
  }

  .title {
    font-family: $base-font;
    color: $text-color;
    font-size: 62px;
    margin-top: -25px;
    padding-left: 3%;
  }

  .form {
    padding: 0 15px 15px 40px;

    .search {
      background-color: $base-color-lighter;
      border: none;
      padding: 15px 35px 13px 35px;
      border-radius: 15px 0 0 15px;
      font-family: $base-font;
      color: $text-color;
      display: block;
      margin-right: -15px;
    }

    @media screen and (max-width: 992px) {
      .search {
        border-radius: 15px;
        margin-right: 0;
      }
    }

  }

  @media screen and (max-width: 992px) {
    .form {
      padding: 5px 5%;
    }
  }


  .card {
    background-color: $base-color-lighter;
    border: none;
    font-family: $base-font;
    font-size: 14px;
    font-weight: 400;
    color: $text-color;
    overflow: hidden;
    margin: 15px 0 15px 0;

    img {
      max-width: 100%;
      transition: all 1.0s;
      display: block;
      width: 100%;
      height: auto;
      //transform: scale(1);
    }

    .card-body {
      padding: 0;

      .card-title {
        position: relative;
        top: -50px;
        font-family: $base-font;
        font-size: 17px;
        font-weight: 500;
        color: #303030;
        margin-bottom: 0;
        border-top: none;
        padding: 15px;
        text-align: center;
        text-transform: uppercase;
        background-color: #E8E0D3;
        opacity: .8;

        &:hover {
          text-shadow: none;
          transition: all 0.1s;
          background-color: $button-color-hover;
          color: $text-color;
        }
      }
    }

    &:hover {
      text-shadow: none;
      transition: all 0.1s;
      //background-color: $base-color-lighter;
      //box-shadow: 0 0 10px 5px rgba(221, 221, 221, 0.01);
    }

  }
}

