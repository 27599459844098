@font-face {
  font-family: 'Marta';
  src: local('Marta'), url('./fonts/marta.woff2') format('woff2'), url('./fonts/marta.woff') format('woff'), url('./fonts/marta.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Marta';
  src: local('Marta Italic'), local('Marta-Italic'), url('./fonts/martaitalic.woff2') format('woff2'), url('./fonts/martaitalic.woff') format('woff'), url('./fonts/martaitalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Marta';
  src: local('Marta Bold'), local('Marta-Bold'), url('./fonts/martabold.woff2') format('woff2'), url('./fonts/martabold.woff') format('woff'), url('./fonts/martabold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
